<template>
  <!-- <tr v-if="choixAffichage == 'Liste'">
    <td>
      <div style="height: 100px; width: 100px">
        <img
          style="height: 100%; width: 100%"
          :src="`${videoProps.thumbnail}`"
          alt
        />
      </div>
    </td>
    <td>
      <h5 class="font-size-14 mb-1 capitalize">{{ videoProps.title }}</h5>
    </td>
    <td>{{ formatDuration(videoProps.duration) }}</td>
    <td>
      <span>{{ videoProps.likes.length }}</span>
    </td>
    <td>
      <span>{{ videoProps.comments.length }}</span>
    </td>
    <td>
      <span>{{ videoProps.nbViews }}</span>
    </td>
    <td>
      <div style="display: flex; justify-content: end">
        <div>
          <span style="color: #0dcaf0; cursor: pointer; font-size: 1.8rem"
            ><i class="mdi mdi-information"></i
          ></span>
          <span
            @click="showUpdateMetadonnees()"
            style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
            ><i class="mdi mdi-pencil-circle"></i
          ></span>
          <span
            @click="confirm()"
            style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
            ><i class="mdi mdi-delete-circle"></i
          ></span>
        </div>
      </div>
    </td>
  </tr> -->

  <div class="col-lg-3 p-1">
    <div>
      <div class="card" style="height: 100%; width: 100%">
        <router-link :to="`/videos/overview/${videoProps.uuid}`">
          <img
            :src="`${videoProps.thumbnail}`"
            alt="image"
            style="border-radius: 7px; height: 100%; width: 100%"
          />
        </router-link>

        <span>
          <i
            class="mdi mdi-play-circle-outline"
            style="
              position: absolute;
              top: 40%;
              right: 45%;
              z-index: 1;
              font-size: 2rem;
              color: #fff;
            "
          ></i>
        </span>
        <span
          style="
            position: absolute;
            top: 83%;
            right: 3%;
            background-color: #dfdfdf;
            border-radius: 5px;
          "
        >
          {{ formatDuration(videoProps.duration) }}
        </span>
        <span
          v-if="isRecent(videoProps.createdAt)"
          style="
            position: absolute;
            top: 3%;
            left: 2%;
            background-color: red;
            border-radius: 5px;
            color: white;
            padding: 3px;
          "
        >
          Nouveau
        </span>
        <span
          v-if="videoProps.isExtract"
          style="
            position: absolute;
            bottom: 3%;
            left: 2%;
            background-color: red;
            border-radius: 5px;
            color: white;
            padding: 2px;
          "
        >
          Extrait
        </span>
      </div>
      <div
        style="margin-top: -26px; display: flex; justify-content: space-between"
      >
        <div>
          <span style="margin-right: 5px">
            <i class="mdi mdi-thumb-up" style="font-size: 1rem"></i>
            {{ videoProps.likes.length }}
          </span>
          <span style="margin-right: 5px">
            <i class="mdi mdi-chat-processing" style="font-size: 1rem"></i>
            {{ videoProps.comments.length }}
          </span>
          <span style="margin-right: 5px">
            <i class="mdi mdi-eye" style="font-size: 1rem"></i>
            {{ videoProps.nbViews }}
          </span>
        </div>
        <div>
          <b-dropdown
            class="card-drop"
            variant="white"
            menu-class="dropdown-menu-end"
            right
            toggle-class="p-0"
            v-if="showOption"
          >
            <template v-slot:button-content>
              <span><i class="mdi mdi-dots-horizontal font-size-18"></i></span>
            </template>
            <b-dropdown-item :href="`/videos/overview/${videoProps.uuid}`"
              >Détails</b-dropdown-item
            >
            <!-- <b-dropdown-item @click="showUpdateModal"
            >{{ $t("pages.videos.actions.modifier") }} les
            images</b-dropdown-item
          > -->
            <b-dropdown-item @click="showUpdateMetadonnees()"
              >Modifier</b-dropdown-item
            >
            <!-- <b-dropdown-item @click="showUpdateVideo()"
            >Modifier la vidéo</b-dropdown-item
          > -->
            <b-dropdown-item @click="confirm()">supprimer</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <h5
        style="
          font-size: 1.1em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        "
      >
        {{ videoProps.title }}
        <span style="font-weight: normal; font-size: 0.7rem">{{
          formatDateByMonth(videoProps.releaseDate)
        }}</span>
      </h5>

      <!-- <b-button
            class="c2play-primary"
            style="position: absolute; z-index: 1; bottom: 0; right: 20px"
          >
          </b-button> -->
    </div>
  </div>

  <div class="col-lg-7 col-xl-3" v-if="choixAffichage == 'Grille'">
    <!-- Simple card -->
    <b-card style="border-radius: 13px">
      <router-link
        :to="'/projects/overview/' + videoProps.id"
        style="color: #303030"
      >
        <div>
          <img
            v-if="videoProps.thumbnail"
            class="card-img"
            :src="`${videoProps.thumbnail}`"
            alt="Card image"
            style="object-fit: cover; opacity: 0.9"
          />
          <!-- <img v-if="!image.url" class="card-img" src="../../assets/logos/unavailable-image.jpg" alt="Card image" style="object-fit: cover; opacity: 0.9; "> -->
          <div
            style="
              top: 20px;
              height: 200px;
              width: 84%;
              position: absolute;
              border-radius: 10px;
              z-index: 1;
              -webkit-box-shadow: inset 0px -185px 112px -95px
                rgba(4, 87, 17, 0.57);
              -moz-box-shadow: inset 0px -185px 112px -95px
                rgba(4, 87, 17, 0.57);
              box-shadow: inset 0px -185px 112px -95px rgba (4, 87, 17, 0.57);
            "
          ></div>
          <h2>
            <i
              class="mdi mdi-play-circle-outline"
              style="
                position: absolute;
                top: 90px;
                left: 95px;
                z-index: 1;
                font-size: 2em;
                color: #fff;
              "
            ></i>
          </h2>
          <h4
            style="
              position: absolute;
              top: 194px;
              left: 30px;
              color: #fff;
              z-index: 1;
            "
          >
            {{ formatDuration(videoProps.duration) }}
          </h4>
        </div>

        <b-card-title>
          <h5
            class="card-title capitalize"
            style="
              font-size: 1.1em;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              margin-bottom: 20px;
            "
          >
            {{ videoProps.title }}
          </h5>
        </b-card-title>
        <b-card-text
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.3em;
          "
        >
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div>
              <i
                class="mdi mdi-thumb-up font-size-22"
                style="margin-right: 5px"
              ></i>
            </div>
            <p>
              {{ videoProps.likedUsers.length }} like<span
                v-if="videoProps.likedUsers.length > 1"
                >s</span
              >
            </p>
          </div>
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div>
              <i
                class="mdi mdi-chat-processing font-size-22"
                style="margin-right: 5px"
              ></i>
            </div>
            <p>{{ video.comment }}</p>
          </div>
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div>
              <i class="mdi mdi-eye font-size-22" style="margin-right: 5px"></i>
            </div>
            <p>
              {{ videoProps.nbVues }} vue<span v-if="videoProps.nbVues > 1"
                >s</span
              >
            </p>
          </div>
        </b-card-text>
      </router-link>
      <b-button
        class="c2play-primary"
        style="margin-left: 35%; margin-right: auto"
      >
        <b-dropdown
          class="card-drop"
          variant="white"
          menu-class="dropdown-menu-end"
          right
          toggle-class="p-0"
        >
          <template v-slot:button-content>
            <span style="color: #fff"
              ><i class="mdi mdi-dots-horizontal font-size-18"></i
            ></span>
          </template>
          <b-dropdown-item :href="'/projects/overview/' + videoProps.id">{{
            $t("pages.videos.actions.details")
          }}</b-dropdown-item>
          <!-- <b-dropdown-item @click="showUpdateModal"
            >{{ $t("pages.videos.actions.modifier") }} les
            images</b-dropdown-item
          > -->
          <b-dropdown-item @click="showUpdateMetadonnees()"
            >Modifier les metadonnées</b-dropdown-item
          >
          <!-- <b-dropdown-item @click="showUpdateVideo()"
            >Modifier la vidéo</b-dropdown-item
          > -->
          <b-dropdown-item @click="confirm(id)">{{
            $t("pages.videos.actions.supprimer")
          }}</b-dropdown-item>
        </b-dropdown>
      </b-button>
    </b-card>
  </div>

  <b-modal
    v-model="updateModal"
    id="modal-ml"
    size="xl"
    title="Modifier la vidéo"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-lg-6">
            <div style="height: 200px; width: 100%; border: 1px solid #ffffff">
              <iframe
                style="width: 100%"
                frameborder="0"
                height="200"
                :src="
                  'https://geo.dailymotion.com/player/xpnus.html?video=' +
                  idDailyMotion
                "
                allowfullscreen
                allow="fullscreen; picture-in-picture; web-share"
              >
              </iframe>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row mb-2">
              <div class="col-lg-12">
                <label for="projectname" class="col-form-label">Titre</label
                ><span style="color: red; font-size: 1.2em">*</span>
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="video.titre"
                  placeholder="Entrez le titre de la vidéo"
                />
              </div>

              <div class="col-lg-12">
                <label class="col-form-label">Programme</label>
                <span style="color: red; font-size: 1.2em">*</span>
                <div class="form-control">
                  <select class="tags-input_text" v-model="video.programme">
                    <option
                      v-for="program in programmes"
                      :key="program.id"
                      :value="program.id"
                    >
                      {{ program.title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                  >
                    <label for="projectdesc" class="col-form-label"
                      >Est-ce un extrait ?</label
                    >
                    <div>
                      <b-form-checkbox
                        v-model="isExcerpt"
                        name="check-button"
                        switch
                        size="lg"
                      >
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de sortie</label
              >
              <input
                id="projectname"
                name="projectname"
                type="date"
                class="form-control"
                v-model="video.publicationDate"
                placeholder="Entrez le titre de la vidéo"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label class="col-form-label">Mots clés</label>
            <div class="form-control">
              <div
                v-for="(tag, index) in keyWords"
                :key="index"
                class="tags-input_tag c2play-primary"
              >
                <span @click="removeKeywords(index)">
                  <i class="mdi mdi-close-thick"></i>
                </span>
                {{ tag }}
              </div>

              <input
                @keydown.enter.prevent="addKeywords"
                @keydown.delete="removeLastKeywords"
                id="taskname"
                name="taskname"
                class="tags-input_text"
                type="text"
                placeholder="Ajoutez un mot clé"
                v-model="enterKeyWords"
              />
            </div>
          </div>
        </div>
        <div class="mb-2 row">
          <div class="col-lg-6">
            <label class="col-form-label">Catégories</label>
            <div class="form-control">
              <div
                v-for="(category, index) in selectedCategorie"
                :key="index"
                class="tags-input_tag c2play-primary"
              >
                <span @click="removeCategory(index)"
                  ><i class="mdi mdi-close-thick"></i
                ></span>
                {{ category }}
              </div>
              <select
                @keydown="addCategory"
                @keydown.delete="removeLastCategory"
                class="tags-input_text"
                @change="addCategory"
                v-model="video.categories"
              >
                <option
                  v-for="category in categoryList"
                  :key="category.id"
                  :value="category.name"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-6">
            <label class="col-form-label">Tags</label>
            <div class="form-control">
              <div
                v-for="(tag, index) in selectedTags"
                :key="index"
                class="tags-input_tag c2play-primary"
              >
                <span @click="removeTags(index)"
                  ><i class="mdi mdi-close-thick"></i
                ></span>
                {{ tag }}
              </div>
              <select
                @keydown="addTags"
                @keydown.delete="removeLastTag"
                class="tags-input_text"
                @change="addTags"
                v-model="video.tags"
              >
                <option v-for="tag in tagList" :key="tag.id" :value="tag.name">
                  {{ tag.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group row mb-2">
          <div class="col-lg-6">
            <label for="projectdesc" class="col-form-label"
              >Description courte</label
            >
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="video.shortDescription"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
          <div class="col-lg-6">
            <label for="projectdesc" class="col-form-label">Description</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="video.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="row">
      <div style="text-align: center">
        <button
          type="submit"
          class="btn c2play-primary"
          @click="updateAllVideos"
        >
          Modifier
        </button>
      </div>
    </div>
  </b-modal>

  <loader-process :visible="process" :progress="progress" :title="titre" />
</template>

<script>
import loaderProcess from "./loaderProcess.vue";
import Swal from "sweetalert2";
import { Api } from "../../helpers";
import { Erreur } from "../../helpers/error";

export default {
  components: {
    loaderProcess,
  },
  data() {
    return {
      updateVideoModal: false,
      titre: "Modification de la video",
      progress: 0,
      updateMode: "",
      process: false,
      updateModal: false,
      isExcerpt: false,
      idDailyMotion: "",
      video: {
        id: "",
        description: "",
        annee: "",
        duree: "",
        categorie: "",
        titre: "",
        channelId: "",
        initialTags: [],
        initialCategories: [],
        programme: "",
        comment: "",
        publicationDate: "",
        shortDescription: "",
        categories: [],
        tags: [],
      },

      tags: [],
      categories: [],
      sendedTag: [],
      sendedCategory: [],
      selectedTags: [],
      selectedCategorie: [],

      tagList: [],
      categoryList: [],
      channels: [],
      videos: [],
      programmes: [],
      choixAffichage: "",

      enterKeyWords: "",
      keyWords: [],
    };
  },
  props: {
    showOption:{
      type: Boolean,
      required: true,
    },
    videoProps: {
      type: Object,
      required: true,
    },
    affichage: {
      default: "",
    },
    chaines: [],
    categ: [],
    tag: [],
    programme: [],
  },
  methods: {
    isRecent(date) {
      const diff = (new Date() - new Date(date)) / (1000 * 60 * 60 * 24);
      return diff <= 3;
    },
    showUpdateMetadonnees() {
      this.updateModal = true;
    },
    addTags(event) {
      event.preventDefault();
      const val = event.target.value;
      if (val.length > 0 && !this.selectedTags.includes(val)) {
        this.selectedTags.push(val);
        this.tagList.forEach((item) => {
          if (item.name == val) {
            this.sendedTag.push(item.id);
          }
        });
        event.target.value = "";
      }
    },

    removeTags(index) {
      this.selectedTags.splice(index, 1);
      this.sendedTag.splice(index, 1);
    },

    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTags(this.selectedTags.length - 1);
      }
    },

    // new category
    addCategory(event) {
      event.preventDefault();
      const val = event.target.value;
      if (val.length > 0 && !this.selectedCategorie.includes(val)) {
        const category = this.categoryList.find((item) => item.name === val);
        if (category) {
          this.selectedCategorie.push(val);
          this.sendedCategory.push(category.id);
        } else {
          console.error("Catégorie non trouvée");
        }
        event.target.value = "";
      } else if (this.selectedCategorie.includes(val)) {
        console.error("Catégorie déjà sélectionnée");
      } else {
        console.error("Valeur de catégorie invalide");
      }
    },

    removeCategory(index) {
      if (index >= 0 && index < this.selectedCategorie.length) {
        this.selectedCategorie.splice(index, 1);
        this.sendedCategory.splice(index, 1);
      } else {
        console.error("Index de catégorie invalide");
      }
    },

    addKeywords(event) {
      event.preventDefault();

      const val = event.target.value.trim();

      if (val.length > 0 && !this.keyWords.includes(val)) {
        this.keyWords.push(val);
        event.target.value = "";
        this.enterKeyWords = "";
      }
    },

    removeKeywords(index) {
      this.keyWords.splice(index, 1);
    },

    removeLastKeywords(event) {
      if (event.target.value.length === 0) {
        this.removeKeywords(this.keyWords.length - 1);
      }
    },

    // removeLastTag(event) {
    //   if (event.target.value.length === 0) {
    //     this.removeTags(this.selectedTags.length - 1);
    //   }
    // },

    // addCategory(event) {
    //   event.preventDefault();
    //   const val = event.target.value.trim();
    //   if (val.length > 0 && !this.selectedCategorie.includes(val)) {
    //     this.selectedCategorie.push(val);
    //     this.categoryList.forEach((item) => {
    //       if (item.name == val) {
    //         this.sendedCategory.push(item.id);
    //       }
    //     });
    //     event.target.value = "";
    //   }
    // },

    // removeCategory(index) {
    //   this.selectedCategorie.splice(index, 1);
    //   this.sendedCategory.splice(index, 1);
    // },

    // removeLastCategory(event) {
    //   if (event.target.value.length === 0) {
    //     this.removeTags(this.selectedCategorie.length - 1);
    //   }
    // },

    formatDuration(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = Math.floor(duration % 60);

      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");

      return `${hoursString}:${minutesString}:${secondsString}`;
    },

    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    formatDateByMonth(date) {
      const d = new Date(date);

      const day = d.getDate();
      const month = d.toLocaleString("fr-FR", { month: "long" });
      const year = d.getFullYear();

      return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
    },
    updateAllVideos() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.process = true;

      this.updateModal = false;
      this.process = true;
      this.titre = "Modification de la vidéo";
      let keyWordsString = this.keyWords.join(',');
      const requestData = {
        categoryIds: this.sendedCategory,
        tagIds: this.sendedTag,
        video: {
          description: this.video.description,
          releaseDate: new Date(this.video.publicationDate),
          shortDescription: this.video.shortDescription,
          title: this.video.titre,
          isExtract: this.isExcerpt,
          keywords: keyWordsString,
        },
      };
      Api.put(
        `/streamvodv2/rest/v2/video/update/${this.videoProps.id}?program_id=${this.video.programme}`,
        requestData,
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Vidéo Modifiée", "success");
          // location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    confirm() {
      Swal.fire({
        title: "Voulez vous vraiment supprimer cett vidéo ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteVideo();
        }
      });
    },

    deleteVideo() {
      Api.delete("/streamvodv2/rest/v2/video/delete/" + this.videoProps.id)
        .then((response) => {
          this.channels = response.data.content;
          Swal.fire("Suppression!", "Vidéo supprimé avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.video.comment = this.videoProps.comments.length;
    this.choixAffichage = this.affichage;
    this.video.id = this.videoProps.id;
    this.video.description = this.videoProps.description;
    this.video.shortDescription = this.videoProps.shortDescription;
    this.video.titre = this.videoProps.title;
    this.video.annee = this.videoProps.years;
    this.video.duree = this.videoProps.duration;
    this.isExcerpt = this.videoProps.isExtract;
    // this.video.channelId = this.videoProps.channel.id;
    // this.video.categorie = this.videoProps.categorie.name;
    this.video.initialTags = this.videoProps.tags;
    this.video.initialCategories = this.videoProps.categories;
    this.video.publicationDate = this.formatDate(this.videoProps.releaseDate);
    this.idDailyMotion = this.videoProps.idDailymotion;
    const keyWordsList = this.videoProps.keywords || ""; // Si keywords est null, assigner une chaîne vide
    this.keyWords = keyWordsList.split(",").map((keyword) => keyword.trim());

    this.video.initialTags.forEach((items) => {
      this.sendedTag.push(items.id);
      this.selectedTags.push(items.name);
    });
    this.video.initialCategories.forEach((items) => {
      this.sendedCategory.push(items.id);
      this.selectedCategorie.push(items.name);
    });

    this.channels = this.chaines;
    this.categoryList = this.categ;
    this.programmes = this.programme;
    this.tagList = this.tag;
    this.video.programme = this.videoProps.programId;
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}

.flotter {
  position: relative;
  transition: ease 1s all;
}

.flotter:hover {
  animation: floating 1.8s ease-in-out infinite;
}

.flotter:after {
  background-color: red;
}
.capitalize::first-letter {
  text-transform: capitalize;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
